window.generate = generate;

function $(id, v) {
  if (v !== undefined) {
    return document.getElementById(id).value = v
  }
  return document.getElementById(id).value
}
function getData() {
  return [
    $('comprador'),
    $('financeira'),
    $('financiado'),
    $('quantidade'),
    $('pagas'),
    $('valor'),
    $('mes'),
    $('ano')
  ]
}
function setData(data) {
    $('comprador',  data[0])
    $('financeira', data[1])
    $('financiado', data[2])
    $('quantidade', data[3])
    $('pagas',      data[4])
    $('valor',      data[5])
    $('mes',        data[6])
    $('ano',        data[7])
}
function getURL(data, type='pdf') {
  return '/generate' + (
    '?type='       + type                        +
    '&comprador='  + encodeURIComponent(data[0]) +
    '&financeira=' + encodeURIComponent(data[1]) +
    '&financiado=' + encodeURIComponent(data[2]) +
    '&quantidade=' + encodeURIComponent(data[3]) +
    '&pagas='      + encodeURIComponent(data[4]) +
    '&valor='      + encodeURIComponent(data[5]) +
    '&mes='        + encodeURIComponent(data[6]) +
    '&ano='        + encodeURIComponent(data[7])
  )
}
function generate(event, redoing) {
  event.preventDefault()

  const data = getData()
  const pdf = getURL(data, 'pdf')
  const doc = getURL(data, 'docx')

  const res = document.getElementById('result')
  res.classList.remove('hide')
  res.scrollIntoView({behavior: 'smooth'})
  res.children[0].setAttribute(
    'src',
    '/pdfjs-web/viewer.html?file='+encodeURIComponent(pdf)
  )

  const $pdf = document.getElementById('pdf')
  $pdf.setAttribute('href', pdf)

  const $doc = document.getElementById('docx')
  $doc.setAttribute('href', doc)

  if (!redoing) save_history(data)

  return false
}

function save_history(data) {
  const load = localStorage.getItem('history')
  const save = load ? JSON.parse(load) : []
  save.push([...data, +new Date()])
  if (save.length > 100) save.unshift()

  localStorage.setItem('history', JSON.stringify(save))
  load_history()
}
function load_history() {
  try {
    const saved = JSON.parse(localStorage.getItem('history') || '[]').reverse()
    if (!saved.length) return;
    const hi = document.getElementById('history')
    hi.classList.remove('hide')
    const hh = hi.children[1]
    hh.innerHTML = ''

    for (const data of saved) {
      const div = document.createElement('div')
      div.setAttribute('class', 'item')
      div.addEventListener('click', () => {
        setData(data)
        generate(event, true)
      })

      const nome = document.createElement('span')
      nome.textContent = data[0]
      nome.setAttribute('class', 'nome')
      div.appendChild(nome)

      const dt = new Date(data[data.length - 1])
      const date = document.createElement('span')
      date.setAttribute('class', 'data')
      date.textContent = dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString().slice(0, -3)
      div.appendChild(date)

      hh.appendChild(div)
    }

  } catch (e) {
    console.error(e)
    localStorage.removeItem('history')
  }
}

window.addEventListener('load', load_history)
